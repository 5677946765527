import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import MoCo_FAQs from "../components/MoCo_FAQs";
import {
  MOCO_CALENDLY_LINK,
  DOWNLOAD_LINK_FAST,
  MOCO_GOOGLE_FORM_LINK,
  environment_,
  CONSUMER_APP_STORE_LINK,
  CONSUMER_PLAY_STORE_LINK,
} from "../helpers/constants";
import CouponCarousel from "../components/CouponCarousel";
import NavBarMoco from "../components/NavBarMoco";
import LoadingIcon from "../components/LoadingIcon";

const MoCo = () => {
  const [coupons, setCoupons] = useState([]);
  const [couponsIsLoading, setCouponsIsLoading] = useState(true);
  const [businesses, setBusinesses] = useState(null);

  useEffect(() => {
    document.title = "MoCoPass";
  }, []);

  useEffect(() => {
    const fetchCoupons = async () => {
      await fetch(
        // process.env.REACT_APP_MOCO_API + environment_ + "/moco/coupons",
        process.env.REACT_APP_MOCO_API + environment_ + "/moco/coupons",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_MOCO_RL_API_KEY,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            console.log(await response.json());

            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Fetched Coupons are is ", data);
          setCoupons(data["coupons"]);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        })
        .finally(() => {
          setCouponsIsLoading(false);
        });
    };

    const fetchBusinesses = async () => {
      await fetch(
        // process.env.REACT_APP_MOCO_API + environment_ + "/moco/coupons",
        process.env.REACT_APP_MOCO_API + environment_ + "/businesses",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_MOCO_RL_API_KEY,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            console.log(await response.json());

            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Fetched businesses are ", data);
          setBusinesses(data);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    };
    fetchCoupons();
    fetchBusinesses();
  }, []);
  const howItWorksRef = useRef(null);
  const FAQsRef = useRef(null);
  const calendlyRef = useRef(null);

  return (
    <div
      className="bg-[#feffff] "
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <NavBarMoco
        calendlyRef={calendlyRef}
        FAQsRef={FAQsRef}
        howItWorksRef={howItWorksRef}
      />
      <div className="mt-[7.5vh] sm:mt-[10vh] md:mt-[12.5vh]  w-full justify-center items-center">
        <div className="moco-title-container flex flex-col">
          <h1 className="font-bold text-3xl sm:text-4xl md:text-5xl text-center pb-8">
            MoCo Passport
          </h1>
          <div className=" flex flex-row justify-center items-center bg-slate">
            <img
              alt="MoCo X Campfire"
              src="images/moco_x_campfire_nobg.png"
              className="w-[90vw] sm:w-[60%] md:w-[35%] xl:w-[25%] object-contain"
            />
          </div>
          {/* <a target="_blank" href={DOWNLOAD_LINK_FAST}>
            <button className="cursor-pointer text-2xl sm:text-2xl  bg-[#FFA33E] hover:bg-[#FF840D] shadow-xl mt-[16px] transition rounded  p-[0.5em] focus:outline-none ">
              Download Now
            </button>
          </a> */}
          <div className="mt-4 flex flex-col md:flex-row justify-center items-center">
            <a
              target="_blank"
              href={CONSUMER_APP_STORE_LINK}
              className="md:mr-4 mb-4 md:mb-0"
            >
              <img
                alt="download on app store"
                src="images/download_app_store.png"
                className="w-[200px]  object-contain"
              />
            </a>
            <a
              target="_blank"
              href={CONSUMER_PLAY_STORE_LINK}
              className="md:ml-4"
            >
              <img
                alt="download on app store"
                src="images/download_play_store.webp"
                className="w-[200px]  object-contain"
              />
            </a>
          </div>
        </div>
        <div className="flex flex-col justify-center px-8 sm:px-16 py-8">
          <h2 className="text-2xl text-center font-bold">
            MoCo Passport Now Has An App!
          </h2>
          <h3 className="text-lg py-4">
            Introducing the brand new Digital MoCo Passport! Approximately $100
            worth of gift passes and free items, all for just $4.99!
            <br /> We are partnering with Campfire Loyalty (a loyalty program
            startup founded by 3 University of Michigan students) to elevate
            your tastebuds!
            <br /> <br /> Try {businesses ? businesses.length : ""} different
            restaurants across all parts of Montgomery County! Download the app
            and subscribe today!
          </h3>
          <div className="mb-16">
            <CouponCarousel
              coupons={coupons}
              couponsIsLoading={couponsIsLoading}
            />
            <h2 className="text-md">
              *This carousel displays real offers that we currently have in the
              pass
            </h2>
            <h4 className="text-sm">
              *Disclaimer: These coupons may be taken down at any time by the
              business owners, we are not liable for any lost.
            </h4>
            <a href="/MoCo/privacy">
              <h4 className="underline">Privacy Policy</h4>
            </a>
            <a href="/MoCo/terms">
              <h4 className="underline">Terms of Use</h4>
            </a>
            <div className="flex justify-center items-center ">
              <a href="/MoCo/offers">
                <button className="cursor-pointer text-2xl sm:text-2xl  bg-white hover:bg-[#FFA33E] shadow-xl mt-[16px] transition rounded  p-[0.5em] focus:outline-none border-solid border-[1px] border-[#140901]">
                  See All Offers
                </button>
              </a>
            </div>
          </div>
          <h3>
            <h4 ref={howItWorksRef} className="text-xl font-bold underline">
              How does it work?
            </h4>
            <h4 className="text-lg py-2">
              1. First, download the Campfire Loyalty App on the{" "}
              <a
                target="_blank"
                href={DOWNLOAD_LINK_FAST}
                className="text-blue-500 hover:text-blue-700 underline"
              >
                App/Play Store
              </a>
            </h4>
            {/* NEED TO INCLUDE SOME SCREENSHOTS */}
            <h4 className="text-lg py-2">
              2. Sign up for an account so you can purchase a MoCoPass
            </h4>
            <h4 className="text-lg py-2">
              3. In the app, go to the MoCoPass screen and subscribe to the
              MoCoPass for just $4.99/month, you can cancel at any time. Once
              you are subscribed, you will unlock ALL the offers in the pass for
              the whole month.
            </h4>
          </h3>
          <h3 className="text-lg py-4">
            <h4 className="text-xl font-bold underline">More Details</h4>
            <h4 className="text-lg py-2">
              These are actual gift passes, free items, and discounts at local
              restaurants. For example, If a gift pass is for $10 at X, that
              means you can spend $10 at X.
              <br />
              <br />
              Full list of participating restaurants:
              {businesses == null ? (
                <LoadingIcon />
              ) : (
                <ul>
                  {businesses.map((item) => {
                    return (
                      <div className="pb-2">
                        <h2 className="font-bold">- {item.name}</h2>
                        <h2 className="text-[#838383]">
                          {item.city}, {item.state} - {item.address}
                        </h2>
                      </div>
                    );
                  })}
                </ul>
              )}
            </h4>
          </h3>
        </div>
        {/* Introducing something about the app? */}
        {/* What does restaurants get out of it? */}
        {/* What do we get out of it? */}
        {/* What is Campfire? */}
        {/* FAQ Section */}
      </div>
      <hr />

      <div ref={calendlyRef}>
        <div className="flex justify-center items-center px-16 py-8">
          <h1 className="text-center text-2xl">
            Are you a business owner and want to learn more? Schedule a meeting
            with us
          </h1>
        </div>
        <iframe
          title="Calendly Form"
          src={MOCO_CALENDLY_LINK}
          width="100%"
          height="800px"
          style={{ border: "1px solid #ddd" }}
        >
          Loading...
        </iframe>
      </div>

      <div>
        <div className="flex justify-center items-center px-16 py-8">
          <h1 className="text-center text-2xl">
            ...Or, you can just fill up this form and we will get back to you!
          </h1>
        </div>
        <iframe
          title="Google Form"
          src={MOCO_GOOGLE_FORM_LINK}
          width="100%"
          height="1200px"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          className="py-8"
          style={{ border: "1px solid #ddd" }}
        >
          Loading...
        </iframe>
      </div>

      <MoCo_FAQs FAQsRef={FAQsRef}></MoCo_FAQs>
      <Footer></Footer>
    </div>
  );
};

export default MoCo;
